import React from 'react'

import { MainShape, StyledContainer, StyledH2, StyledLink, StyledSpan } from './Main.style'

import Grid from '@material-ui/core/Grid'

const Main = () => (
  <Grid container>
    <MainShape item>
      <StyledContainer>
        <StyledH2>Contact Us</StyledH2>
        <p>
          <StyledLink to="/">Home</StyledLink>
          <StyledSpan> / Contact Us</StyledSpan>
        </p>
      </StyledContainer>
    </MainShape>
  </Grid>
)

export default Main

import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Location from '../components/Contact/Location/Location'
import Main from '../components/Contact/Main/Main'

const Contact = () => (
  <Layout>
    <SEO title="Contact" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="If you're ready to launch your innovative idea or transform an existing project, give us a call."
      />
      <title>Contact Us | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/contact" />
    </Helmet>

    <Main />
    <Location />
  </Layout>
)

export default Contact

import { Link } from 'gatsby'

import colors from '../../../styles/colors'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const MainShape = styled(Grid)`
  background-image: linear-gradient(${colors.primaryLight}, ${colors.primaryDark});
  height: 400px;
  color: white;
  margin: 0;
  width: 100vw;
`

export const StyledContainer = styled(Container)`
  text-align: center;
  padding-top: 200px;
  font-size: 2rem;
`

export const StyledH2 = styled.h2`
  margin-top: 0;
  font-weight: 600;
`

export const StyledLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none;
  color: white;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }
`

export const StyledSpan = styled.span`
  color: ${colors.textLight};
  font-size: 1rem;
`
